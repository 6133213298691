//********************
//Load Function
//********************
$(window).on("load",function(){
    $(".contentScroll").mCustomScrollbar();

    //Pre Loader
    $('#preloader').delay(350).fadeOut(function(){
	  	$('body').delay(350).css({'overflow':'visible'});
   	});
});


//********************
//Ready Function
//********************
$(document).ready(function(){

	//********************
	//Hamburger Menu
	//********************
	$('.navToggle').on('click', function(){
		$(this).addClass('is-active');
	});
	$('body').on('click', function(){
		$('.navToggle').removeClass('is-active');
	});

    //SideNav Dropdown
    $('.side-nav .has-dropdown > a').on('click', function(e){
    	$(this).next('.sub-menu').slideToggle();

    	e.preventDefault();
    });


	//********************
	// the "href" attribute of .modal-trigger must specify the modal ID that wants to be triggered
	//********************
	


	//********************
	// Tooltip
	//********************
	



    //********************
	//Carousel Slider
	//********************
    if ($('.owl-carousel').length > 0) {
	    
	    $('.owl-carousel').owlCarousel({
		    center: true,
		    items:1,
		    dots: false,
		    nav: true,
		    navText: ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'],
		    onInitialized: callback,
		});
		function callback(event) {
		    var items = event.item.count;
		    var item = event.item.index + 1;
		    $('.num').html(''+item+'/'+items+'');
		}

		// Listen to owl events:
	    $('.owl-carousel').on('changed.owl.carousel', function(event) {
	        var items = event.item.count;
		    var item = event.item.index + 1;
	        $('.num').html(''+item+'/'+items+'');
	    })
	}



});
